.heading {
  @apply pb-4;

  & > * {
    /*
    Keep this identical to class in utilities;
    @apply element-anchor-scroll-position;
    */
    @apply scroll-mt-[90px] sm:scroll-mt-[120px] lg:scroll-mt-[160px];
  }

  /* Slightly more gap when heading is followed by theme-block */
  &:global(.page),
  &:global(.campaign) {
    & + div[data-block^="theme/"] {
      @apply pt-6;
    }
  }

  &:global(.layout-align-left) {
    @apply max-w-8xl mx-auto;
    & > * {
      @apply max-w-1xl;
    }
  }

  @media print {
    break-inside: avoid;
  }
}
